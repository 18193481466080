<template>
  <a-modal
    title="新建"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok',this.map) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="商品条形码" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['barcode', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

export default {
  name: 'CreateCouponGoodsForm',
  components: {},
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      map: {}
    }
  }
}
</script>
<style>
</style>
